import { Button, Row, Col, Container, Form, Toast } from "react-bootstrap"
import Select from "react-select"
import React, { useState } from "react"
import Klauza from "../../files/KLAUZULA-INFORMACYJNA-RODO.pdf"
import Regulamin from "../../files/Regulamin-Zajęć.pdf"
import { MdMail, MdPhone } from "react-icons/md"
import { AiFillBank } from "react-icons/ai"
import "./style.scss"
import { FaMapMarker } from "react-icons/fa"
import axios from "axios"

export default function Kontakt(props) {
  const [isOpiekun, setOpiekun] = useState(true)
  const [showA, setShowA] = useState(false)
  const hideShowA = () => {
    setShowA(false)
  }
  let mySubmitHandler = (event) => {
    let form = {
      _1_imie: event.target._1_imie.value,
      _2_nazwisko: event.target._2_nazwisko.value,
      _6_email: event.target._6_email.value,
      _8_wiadomosc: event.target._8_wiadomosc.value,
      _9_typ_formularza: 'kontakt'
    }
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key] !== "") {
        formData.append(key, form[key]);
      }
    })
    axios.post("https://script.google.com/macros/s/AKfycbxI2WlzQakBRVahAbABrari802keNhwPikrDbprtV1kSnPjODUrvV6j6xAPfssYi2dG/exec", formData);
    event.preventDefault()
    event.target.reset()
    setShowA(true);
    setTimeout(() => {
      setShowA(false);
    }, 10000);
    return false
  }

  return (
    <Container>
      <Row>
        <Col md={3}>
          <h3 className={"text-center mt-3"}>NAPISZ DO NAS</h3>
          <Form className={"gform center"} method={"POST"}
                action={"https://script.google.com/macros/s/AKfycbxI2WlzQakBRVahAbABrari802keNhwPikrDbprtV1kSnPjODUrvV6j6xAPfssYi2dG/exec"}
                onSubmit={mySubmitHandler}
          >
            <Toast className={"mx-auto"} style={{ display: showA ? "block" : "none" }} onClose={hideShowA}>
              <Toast.Header>
                <strong className="mr-auto">profildancecompany</strong>
              </Toast.Header>
              <Toast.Body>Wiadomość została wysłana!</Toast.Body>
            </Toast>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Imię</Form.Label>
                <Form.Control
                  required
                  name="_1_imie"
                  type="text"
                  placeholder="Imię"
                />
              </Form.Group>
              <Form.Group controlId="validationCustom02">
                <Form.Label>Nazwisko</Form.Label>
                <Form.Control
                  required
                  name="_2_nazwisko"
                  type="text"
                  placeholder="Nazwisko"
                />
              </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>E-mail kontaktowy</Form.Label>
              <Form.Control required name={"_6_email"} type="email" placeholder="przykladowy@email.com"/>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Wiadomość</Form.Label>
              <Form.Control name={"_8_wiadomosc"} as="textarea" rows="3"/>
            </Form.Group>

            <Form.Group>
              <Form.Check
                style={{ float: "left" }}
                required
                feedback="Pole wymagane."
              />
              <div>Zapoznałem się i akceptuję treść <a href={Klauza}>klauzuli
                informacyjnej RODO</a></div>
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Wyślij wiadomość
              </Button>
            </div>
            <Toast className={"mx-auto mt-4"} style={{ display: showA ? "block" : "none" }} onClose={hideShowA}>
              <Toast.Header>
                <strong className="mr-auto">profildancecompany</strong>
                {/*<small>-></small>*/}
              </Toast.Header>
              <Toast.Body>Wiadomość został wysłany!</Toast.Body>
            </Toast>
          </Form>
        </Col>
        <Col md={6} className={'mt-5'}>

          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1ew6H_KUPlLFr5CRz0iqyfoQ0NYSUrNs" width="100%" height="480"></iframe>
        </Col>
        <Col md={3}>
          <h3 className={"text-center mt-3"}>KONTAKT</h3>
          <MdPhone size={22} className={"brown-icon"}/> <a href="tel:787998001"
                                                           className="contactBarHrefTo black"> 787998001</a>
          <br/>
          <MdMail size={22} className={"brown-icon"}/> <a href="mailto:kontakt@profildance.com"
                                                          className="contactBarHrefTo black"> kontakt@profildance.com</a>
          <br/>
          <br/>
          <span>
                <AiFillBank size={22} className={"brown-icon"}/> Profil Dance Ewa Konecka<br/>
                mBank 70 1140 2004 0000 3302 8036 1839
              </span>
          <h3 className={"text-center mt-3"}>LOKAZLIZACJE ZAJĘĆ</h3>
          <FaMapMarker size={22} className={"brown-icon"}/><a href="https://goo.gl/maps/iTFAGuJamHb5W5Wv5"
                                                              target={"_blank"} className="contactBarHrefTo black">Sala
          Taneczna Dom Kultury Rakowiec ul. Wiślicka 8</a><br/>
          <FaMapMarker size={22} className={"brown-icon"}/><a href="https://goo.gl/maps/TpjTFmgvC8hCpCg97"
                                                              target={"_blank"} className="contactBarHrefTo black">Sala
          Widowiskowa Dzielnicowy Ośrodek Kultury ul. Kajakowa 12b</a><br/>
          <FaMapMarker size={22} className={"brown-icon"}/><a href="https://goo.gl/maps/DBm2ByfmVosbiZDu6"
                                                              target={"_blank"} className="contactBarHrefTo black">Sala
          Gimnastyczna CKU2 ul. Szczęśliwicka 50/54</a><br/>
          <FaMapMarker size={22} className={"brown-icon"}/><a href="https://goo.gl/maps/VZEXNSnEiB37n3Js7"
                                                              target={"_blank"} className="contactBarHrefTo black">Sala
          Gimnastyczna SP384 ul. Kajakowa 10</a><br/>
          <FaMapMarker size={22} className={"brown-icon"}/><a href="https://g.page/Viva-Cuba-Dance-Studio?share"
                                                              target={"_blank"} className="contactBarHrefTo black">Sala
          Taneczna Viva Cuba Dance Studio ul. Marszałkowska 115</a><br/>
          <FaMapMarker size={22} className={"brown-icon"}/><a href="https://maps.app.goo.gl/7AwKoUPqBzndR9mw9"
                                                              target={"_blank"} className="contactBarHrefTo black">Sala
          Gimnastyczna SP 100 ul. Taneczna 54/58 Ursynów</a><br/>
        </Col>
      </Row>
    </Container>
  )
}




