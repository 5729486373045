import React from "react"
import { Form, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Kontakt from "../components/kontakt"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "kontakt" }}>
    <SEO title="Kontakt"/>
    <Kontakt/>
  </Layout>
)

export default SecondPage
